<template>
    <v-list :dense="$vuetify.breakpoint.smAndDown" subheader>
        <template v-if="loading">
            <v-skeleton-loader type="list-item@15"/>
        </template>
        <template v-else>
            <v-list-item to="/">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Dashboard
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-subheader>Content Creator</v-subheader>
            <v-list-item :to="{name: 'Creator', params: {creator_id: creator.id}}" v-for="creator in defaultCreators"
                         :key="creator.id">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        {{ creator.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'SocietyCreators'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Fachgesellschaften
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'Industry'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Industrie
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'Others'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Weitere
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item :to="{name: 'Users'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Benutzerverwaltung
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item :to="{name: 'Experts'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Experten
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'Supporters'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Supporter
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'MenuPartnersPlace'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Hauptmenü & Startseite
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'CmeOverview'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        CME
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <template v-if="can('marketing.view')">
                <v-list-item :to="{name: 'MarketingUserActivity'}">
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 font-weight-regular">
                            Marketing
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="can('staticPage.edit')">
                <v-list-item :to="{name: 'StaticPages'}">
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 font-weight-regular">
                            Statische Seiten
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template>
                <v-list-item :to="{name: 'IndexPageTestimonials'}">
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 font-weight-regular">
                            Startseite vor Login
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template>
<!--            <template v-if="can('order.view')">-->
                <v-list-item :to="{name: 'Orders'}">
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 font-weight-regular">
                            Bestellungen
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-divider/>
            <v-subheader>Auswertung</v-subheader>
            <v-list-item :to="{name: 'UserAnalysis'}">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Benutzer Auswertung
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item @click="onLogout">
                <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">
                        Abmelden
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import {mapGetters} from "vuex";
import creators from "@/graphql/queries/creator/creators.graphql";
import {onLogout} from "@/plugins/vue-apollo";

export default {
    name: 'SuMenuList',
    data() {
        return {
            creators: true,
            menuItems: true,
            loading: true,
        }
    },
    methods: {
        onLogout() {
            onLogout(this.$apollo.getClient())
        }
    },
    computed: {
        ...mapGetters('permissions', ['can']),
    },
    apollo: {
        defaultCreators: {
            query: creators,
            update(data) {
                return data.creators.data
            },
            result({loading}) {
                this.loading = loading
            },
            variables() {
                return {
                    type: this.$enums.CreatorType.DEFAULT.key,
                    first: 100,
                }
            }
        }
    }
};
</script>

<style scoped>
</style>
