import Vue from 'vue'
import Vuex from 'vuex'
import _map from "lodash/map";
import vapor from "@/plugins/vapor";
import initVodUpload from "@/graphql/mutations/vod/initVodUpload.graphql";
import initShortUpload from "@/graphql/mutations/short/initShortUpload.graphql";
import completeVodUpload from "@/graphql/mutations/vod/completeVodUpload.graphql";
import completeShortUpload from "@/graphql/mutations/short/completeShortUpload.graphql";
import transcodeVod from "@/graphql/mutations/vod/transcodeVod.graphql";
import transcodeShort from "@/graphql/mutations/vod/transcodeVod.graphql";
import extractELearning from "@/graphql/mutations/vod/extractELearning.graphql";
import dayjs from "@/plugins/dayjs";


const beforeUnloadListener = (event) => {
    event.preventDefault();
    return event.returnValue = "Are you sure you want to exit?";
};


Vue.use(Vuex)

const userAnalysis = {
    namespaced: true,
    state: () => ({
        min_date: null,
        max_date: dayjs(),
        countries: [],
        professions: [],
        employments: [],
        fields: [],
        timelineResolution: 'MONTH',
    }),
    mutations: {
        setMinDate(state, payload) {
            state.min_date = payload
        },
        setMaxDate(state, payload) {
            state.max_date = payload
        },
        setCountries(state, payload) {
            state.countries = payload
        },
        setProfessions(state, payload) {
            state.professions = payload
        },
        setEmployments(state, payload) {
            state.employments = payload
        },
        setFields(state, payload) {
            state.fields = payload
        },
        setTimelineResolution(state, payload) {
            state.timelineResolution = payload
        },
        resetFilter(state) {
            state.countries = []
            state.professions = []
            state.employments = []
            state.fields = []
        },
    },
    getters: {
        isFieldFiltered(state) {
            return (state.fields && state.fields.length > 0)
        },
        isDemographicFiltered(state) {
            return (
                (state.countries && state.countries.length > 0)
                || (state.professions && state.professions.length > 0)
                || (state.employments && state.employments.length > 0)
            )
        },
        input(state) {
            return {
                min_date: state.min_date?.format('YYYY-MM'),
                max_date: state.max_date.format('YYYY-MM'),
                timeline_resolution: state.timelineResolution,
                countries: state.countries,
                professions: state.professions,
                employments: state.employments,
                fields: state.fields,
            }
        }
    }
}

const videoAnalysis = {
    namespaced: true,
    state: () => ({
        vod_id: null,
        from: null,
        to: null,
        fields: [],
        subscriptions: [],
        resolution: 'YEAR',
    }),
    mutations: {
        setFrom(state, payload) {
            state.from = payload
        },
        setTo(state, payload) {
            state.to = payload
        },
        setFields(state, payload) {
            state.fields = payload
        },
        setSubscriptions(state, payload) {
            state.subscriptions = payload
        },
        setResolution(state, payload) {
            state.resolution = payload
        },
        setVodId(state, payload) {
            state.vod_id = payload
        },
    },
    getters: {
        isFiltered(state) {
            return (
                (state.fields && state.fields.length > 0)
                || (state.subscriptions && state.subscriptions.length > 0)
            )
        },
        hasDateRange(state) {
            return (
                state.from || state.to
            )
        },
        input(state) {
            return {
                from: state.from,
                to: state.to,
                resolution: state.resolution,
                fields: state.fields,
                vod_id: state.vod_id,
            }
        }
    }
}

const livestreamAnalysis = {
    namespaced: true,
    state: () => ({
        livestream_id: null,
        fields: [],
    }),
    mutations: {
        setFields(state, payload) {
            state.fields = payload
        },
        setLivestreamId(state, payload) {
            state.livestream_id = payload
        },
    },
    getters: {
        isFiltered(state) {
            return (
                (state.fields && state.fields.length > 0)
            )
        },
        input(state) {
            return {
                fields: state.fields,
                livestream_id: state.livestream_id,
            }
        }
    }
}

const channelSalesAnalysis = {
    namespaced: true,
    state: () => ({
        channel_id: null,
        from: dayjs().tz('Europe/Berlin').subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD'),
        to: dayjs().tz('Europe/Berlin').format('YYYY-MM-DD'),
        buyableType: null,
        prices: [],
        autoDiscounts: [],
    }),
    mutations: {
        setFrom(state, payload) {
            state.from = payload
        },
        setTo(state, payload) {
            state.to = payload
        },
        setBuyableType(state, payload) {
            state.buyableType = payload
        },
        setPrices(state, payload) {
            state.subscriptions = payload
        },
        setAutoDiscounts(state, payload) {
            state.autoDiscounts = payload
        },
    },
    getters: {
        input(state) {
            return {
                channel_id: state.channel_id,
                from: state.from,
                to: state.to,
                buyableType: state.buyableType,
                prices: state.prices,
                autoDiscounts: state.autoDiscounts,
            }
        }
    },
}

const creatorSalesAnalysis = {
    namespaced: true,
    state: () => ({
        channel_id: null,
        from: dayjs().tz('Europe/Berlin').subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD'),
        to: dayjs().tz('Europe/Berlin').format('YYYY-MM-DD'),
        prices: [],
        autoDiscounts: [],
        buyableType: null,
    }),
    mutations: {
        setFrom(state, payload) {
            state.from = payload
        },
        setTo(state, payload) {
            state.to = payload
        },
        setBuyableType(state, payload) {
            state.buyableType = payload
        },
        setPrices(state, payload) {
            state.subscriptions = payload
        },
        setAutoDiscounts(state, payload) {
            state.autoDiscounts = payload
        },
    },
    getters: {
        input(state) {
            return {
                channel_id: state.channel_id,
                from: state.from,
                to: state.to,
                buyableType: state.buyableType,
                prices: state.prices,
                autoDiscounts: state.autoDiscounts,
            }
        }
    },
}

const menu = {
    namespaced: true,
    state: () => ({
        open: false,
    }),
    mutations: {
        setOpen(state, open) {
            state.open = open
        }
    },
}

const permissions = {
    namespaced: true,
    state: () => ({
        list: [],
    }),
    mutations: {
        setList(state, list) {
            state.list = list
        }
    },
    getters: {
        can: (state) => (permission) => {
            return state.list.includes(permission)
        }
    }
}

const videoUploads = {
    namespaced: true,
    state: () => ({
        list: {},
        pollableQuery: null,
    }),
    mutations: {
        addUpload(state, payload) {
            Vue.set(state.list, payload.id, {
                id: payload.id,
                name: payload.name,
                type: payload.type,
                progress: 0
            })
            removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
            addEventListener("beforeunload", beforeUnloadListener, {capture: true});
        },
        setPollableQuery(state, query) {
            state.pollableQuery = query
        },
        updateProgress(state, payload) {
            const upload = state.list[payload.id]
            if(upload) {
                upload.progress = payload.progress
            }
        },
        removeUpload(state, id) {
            Vue.delete(state.list, id)
            removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
        }
    },
    actions: {
        async start({state, dispatch, commit}, payload) {
            commit('addUpload', {
                id: payload.videoId,
                name: payload.videoName,
                type: payload.type,
                progress: 0
            })
            if (payload.type === payload.enums.VideoType.VOD.key || payload.type === payload.enums.VideoType.SHORT.key) {
                let isVod = payload.type === payload.enums.VideoType.VOD.key
                let uploadPath = await vapor.storeMultipart(
                    payload.video,
                    isVod ? initVodUpload : initShortUpload,
                    isVod ? 'initVodUpload' : 'initShortUpload',
                    isVod ? completeVodUpload : completeShortUpload,
                    payload.apollo,
                    (progress) => {
                        commit('updateProgress', {progress: progress, id: payload.videoId})
                    })
                    .catch((e) => {
                        commit('removeUpload', payload.videoId)
                        dispatch('errorAlert/serverErrors', e, {root: true})
                    });
                commit('updateProgress', {progress: 0, id: payload.videoId})
                if (uploadPath) {
                    payload.apollo.mutate({
                            mutation: isVod ? transcodeVod : transcodeShort,
                            variables: {
                                id: payload.videoId,
                                sourcePath: uploadPath,
                            },
                        },
                    ).then(() => {
                        commit('removeUpload', payload.videoId)
                        if (state.pollableQuery) {
                            state.pollableQuery.refetch()
                            state.pollableQuery.startPolling(10000)
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    dispatch('errorAlert/serverErrors', {}, {root: true})
                    this.loading = false
                }
            } else if (payload.type === payload.enums.VodType.E_LEARNING.key) {
                let res = await vapor.store(payload.video, require(`@/graphql/mutations/aws/signedStorageUrlELearningUploads.graphql`),
                    'signedStorageUrlELearningUploads',
                    payload.apollo,
                    (progress) => {
                        commit('updateProgress', {progress: progress, id: payload.videoId})
                    })
                    .then((uuid) => {
                        commit('updateProgress', {progress: 0, id: payload.videoId})
                        return {
                            uuid: uuid,
                        };
                    })
                    .catch((e) => {
                        commit('removeUpload', payload.videoId)
                        dispatch('errorAlert/serverErrors', e, {root: true})
                    });
                if (res && res.uuid) {
                    let variables = {vodId: payload.videoId, uuid: res.uuid}
                    if (payload.type === payload.enums.VodType.E_LEARNING.key) {
                        variables.duration = parseInt(payload.duration)
                    }
                    payload.apollo.mutate({
                            mutation: extractELearning,
                            variables: variables,
                            update: () => {
                                commit('removeUpload', payload.videoId)
                                if (state.pollableQuery) {
                                    state.pollableQuery.refetch()
                                    state.pollableQuery.startPolling(10000)
                                }
                            },
                        },
                    ).finally(() => {
                        this.loading = false;
                    });
                } else {
                    dispatch('errorAlert/serverErrors', {}, {root: true})
                    this.loading = false
                }
            }

        },
    }
}

const search = {
    namespaced: true,
    state: () => ({
        globalTerm: '',
    }),
    mutations: {
        setGlobalTerm(state, globalTerm) {
            state.globalTerm = globalTerm
        }
    },
}

const successAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        text: '',
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setText(state, text) {
            state.text = text
        },
    },
    actions: {
        success({commit}, text = 'Erfolgreich gespeichert!') {
            commit('setText', text)
            commit('setActive', true)
            commit('errorAlert/setActive', false, {root: true})
        }
    }
}

const errorAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        serverErrors: [],
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setServerErrors(state, errors) {
            state.serverErrors = errors
        },
    },
    actions: {
        serverErrors({commit}, {e, message}) {
            if (e && 'graphQLErrors' in e) {
                let messages = e.graphQLErrors[0].extensions.validation;
                if (messages) {
                    commit('setServerErrors', _map(messages, (message) => {
                        return message[0]
                    }))
                } else {
                    commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
                }
            } else if (message) {
                commit('setServerErrors', [message])
            } else {
                commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
            }
            commit('setActive', true)
            commit('successAlert/setActive', false, {root: true})
        }
    }
}

const apolloState = {
    namespaced: true,
    state: () => ({
        apolloLoading: false,
        apolloLoadingQueriesCount: 0,
    }),
    mutations: {
        setApolloLoading(state, loading) {
            state.apolloLoading = loading
        },
        setApolloLoadingQueriesCount(state, count) {
            state.apolloLoadingQueriesCount = count
        },
    },
}

const userManagement = {
    namespaced: true,
    state: () => ({
        id: null,
        efn: null,
        email: null,
        firstName: null,
        lastName: null,
        role: null
    }),
    mutations: {
        setId(state, id) {
            state.id = id
        },
        setEfn(state, efn) {
            state.efn = efn
        },
        setEmail(state, email) {
            state.email = email
        },
        setFirstName(state, firstName) {
            state.firstName = firstName
        },
        setLastName(state, lastName) {
            state.lastName = lastName
        },
        setRole(state, role) {
            state.role = role
        },
    },
    getters: {
        getId(state) {
            return state.id
        },
        getEfn(state) {
            return state.efn
        },
        getEmail(state) {
            return state.email
        },
        getFirstName(state) {
            return state.firstName
        },
        getLastName(state) {
            return state.lastName
        },
        getRole(state) {
            return state.role
        }
    },
    actions: {
        resetFilter({commit}) {
            commit('setId', null)
            commit('setEfn', null)
            commit('setFirstName', null)
            commit('setLastName', null)
            commit('setEmail', null)
            commit('setRole', null)
        }
    }
}
export default new Vuex.Store({
    modules: {
        menu: menu,
        successAlert: successAlert,
        errorAlert: errorAlert,
        apolloState: apolloState,
        videoUploads: videoUploads,
        permissions: permissions,
        userAnalysis: userAnalysis,
        userManagement: userManagement,
        videoAnalysis: videoAnalysis,
        livestreamAnalysis: livestreamAnalysis,
        channelSalesAnalysis: channelSalesAnalysis,
        search: search,
        creatorSalesAnalysis: creatorSalesAnalysis,
    },
})
