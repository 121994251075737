import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/Dashboard'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {hideMenu: true},
        component: () => import('../views/Login'),
    },
    {
        path: '/industry',
        name: 'Industry',
        component: () => import('../views/Industry'),
    },
    {
        path: '/society_creators',
        name: 'SocietyCreators',
        component: () => import('../views/SocietyCreators'),
    },
    {
        path: '/others',
        name: 'Others',
        component: () => import('../views/Others'),
    },
    {
        path: '/supporters',
        name: 'Supporters',
        component: () => import('../views/Supporters'),
    },
    {
        path: '/experts',
        name: 'Experts',
        component: () => import('../views/Experts'),
    },
    {
        path: '/users',
        component: () => import('../views/Users'),
        children: [
            {
                path: '',
                name: 'Users',
                component: () => import('../components/user/UserList'),
            },
            {
                path: '/user_validations',
                name: 'UserValidations',
                component: () => import('../components/user/UserValidations.vue'),
            },
        ]
    },
    {
        path: '/user_analysis',
        name: 'UserAnalysis',
        component: () => import('../views/UserAnalysis'),
    },
    {
        path: '/cme',
        component: () => import('../views/Cme'),
        children: [
            {
                path: '',
                name: 'CmeOverview',
                component: () => import('../components/cme/CmeOverview'),
            },
            {
                path: 'analysis',
                component: () => import('../components/cme/CmeAnalysis.vue'),
                children: [
                    {
                        path: '',
                        name: 'CmeGlobalAnalysis',
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import('../components/cme/analysis/CmeGlobalAnalysis.vue'),
                    },
                    {
                        path: 'creator/:creator_id',
                        name: 'CmeCreatorAnalysis',
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import('../components/cme/analysis/CmeCreatorAnalysis.vue'),
                    },
                    {
                        path: 'channel/:channel_id',
                        name: 'CmeChannelAnalysis',
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import('../components/cme/analysis/CmeChannelAnalysis.vue'),
                    },
                ]
            },
        ]
    },
    {
        path: '/marketing',
        component: () => import('../views/Marketing'),
        children: [
            {
                path: '',
                name: 'MarketingUserActivity',
                component: () => import('../components/marketing/MarketingUserActivity'),
            },
            {
                path: 'notifications',
                name: 'MarketingNotificationPlanning',
                component: () => import('../components/marketing/MarketingNotificationPlanning.vue'),
            },
            {
                path: 'notifications_analysis',
                name: 'MarketingNotificationAnalysis',
                component: () => import('../components/marketing/MarketingNotificationAnalysis.vue'),
            },
            {
                path: 'settings_analysis',
                name: 'MarketingNotificationSettingsAnalysis',
                component: () => import('../components/marketing/MarketingNotificationSettingsAnalysis.vue'),
            },
        ]
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('../views/Orders'),
    },
    {
        path: '/search/:term',
        name: 'Search',
        component: () => import('../views/Search'),
    },
    {
        path: '/user/:user_id',
        component: () => import('../views/User'),
        children: [
            {
                path: '',
                name: 'User',
                component: () => import('../components/user/EditUser'),
            },
            {
                path: 'videos',
                name: 'UserVideos',
                component: () => import('../components/user/video/UserVideos'),
            },
            {
                path: 'livestreams',
                name: 'UserLivestreams',
                component: () => import('../components/user/livestream/UserLivestreams'),
            },
            {
                path: 'playlists',
                name: 'UserPlaylists',
                component: () => import('../components/user/playlist/UserPlaylists'),
            },
            {
                path: 'events',
                name: 'UserEvents',
                component: () => import('../components/user/event/UserEvents'),
            },
            {
                path: 'certificates',
                name: 'UserCertificates',
                component: () => import('../components/user/certificate/UserCertificates'),
            },
        ]
    },
    {
        path: '/creator/:creator_id',
        component: () => import('../views/Creator'),
        children: [
            {
                path: '',
                name: 'Creator',
                component: () => import('../components/creator/CreatorChannelList'),
            },
            {
                path: 'landing_pages',
                name: 'CreatorLandingPages',
                component: () => import('../components/creator/CreatorLandingPageList'),
            },
            {
                path: 'featured_content',
                name: 'CreatorFeaturedContent',
                component: () => import('../components/creator/CreatorFeaturedContentCalendar'),
            },
            {
                path: 'settings',
                name: 'CreatorSettings',
                component: () => import('../components/creator/EditCreator'),
            },
            {
                path: 'analysis',
                name: 'CreatorAnalysis',
                component: () => import('../components/creator/analysis/CreatorAnalysis.vue'),
            },
            {
                path: 'sales',
                name: 'CreatorSales',
                component: () => import('../components/creator/sales/CreatorSales.vue'),
            },
            {
                path: 'pre_rolls',
                name: 'CreatorPreRolls',
                component: () => import('../components/creator/CreatorPreRolls.vue'),
            },
        ]
    },
    {
        path: '/playlist/:playlist_id',
        component: () => import('../views/Playlist'),
        children: [
            {
                path: '',
                name: 'Playlist',
                component: () => import('../components/playlist/PlaylistVodList'),
            },
            {
                path: 'settings',
                name: 'PlaylistSettings',
                component: () => import('../components/playlist/EditPlaylist'),
            },
            {
                path: 'sales',
                name: 'PlaylistSales',
                component: () => import('../components/playlist/sales/PlaylistSales.vue'),
            },
        ]
    },
    {
        path: '/channel/:channel_id',
        component: () => import('../views/Channel'),
        children: [
            {
                path: '',
                name: 'Channel',
                component: () => import('../components/channel/ChannelLivestreamList'),
            },
            {
                path: 'event',
                name: 'ChannelEvents',
                component: () => import('../components/channel/ChannelEventList'),
            },
            {
                path: 'vods',
                name: 'ChannelVods',
                component: () => import('../components/channel/ChannelVodList'),
            },
            {
                path: 'shorts',
                name: 'ChannelShorts',
                component: () => import('../components/channel/ChannelShortList'),
            },
            {
                path: 'playlists',
                name: 'ChannelPlaylists',
                component: () => import('../components/channel/ChannelPlaylistList'),
            },
            {
                path: 'settings',
                name: 'ChannelSettings',
                component: () => import('../components/channel/EditChannel'),
            },
            {
                path: 'experts',
                name: 'ChannelExperts',
                component: () => import('../components/channel/ChannelExpertList'),
            },
            {
                path: 'featured_content',
                name: 'ChannelFeaturedContent',
                component: () => import('../components/channel/ChannelFeaturedContentCalendar'),
            },
            {
                path: 'analysis',
                name: 'ChannelAnalysis',
                component: () => import('../components/channel/analysis/ChannelAnalysis.vue'),
            },
            {
                path: 'sales',
                name: 'ChannelSales',
                component: () => import('../components/channel/sales/ChannelSales.vue'),
            },
        ]
    },
    {
        path: '/landing_page/:landing_page_id',
        component: () => import('../views/LandingPage'),
        children: [
            {
                path: '',
                name: 'LandingPage',
                component: () => import('../components/landingpages/LandingPageSections'),
            },
            {
                path: 'settings',
                name: 'LandingPageSettings',
                component: () => import('../components/landingpages/EditLandingPage'),
            },
        ]
    },
    {
        path: '/static_pages',
        name: 'StaticPages',
        component: () => import('../views/StaticPages'),
    },
    {
        path: '/static_page/:static_page_id',
        component: () => import('../views/StaticPage'),
        children: [
            {
                path: '/',
                name: 'StaticPage',
                component: () => import('../components/staticpages/StaticPageSections'),
            },
            {
                path: 'settings',
                name: 'StaticPageSettings',
                component: () => import('../components/staticpages/EditStaticPage'),
            },
        ]
    },
    {
        path: '/vod/:vod_id',
        component: () => import('../views/Vod'),
        children: [
            {
                path: '',
                name: 'Vod',
                component: () => import('../components/vod/EditVod'),
            },
            {
                path: 'topics',
                name: 'VodTopicsAndExperts',
                component: () => import('../components/vod/topic/VodTopicAndExpertList.vue'),
            },
            {
                path: 'supporter',
                name: 'VodSupporter',
                component: () => import('../components/vod/supporter/VodSupporterList'),
            },
            {
                path: 'certificates',
                name: 'VodCertificates',
                component: () => import('../components/vod/certificate/VodCertificateList'),
            },
            {
                path: 'downloads',
                name: 'VodDownloads',
                component: () => import('../components/vod/download/VodDownloadList'),
            },
            {
                path: 'access',
                name: 'VodAccess',
                component: () => import('../components/vod/VodAccess'),
            },
            {
                path: 'citations',
                name: 'VodCitations',
                component: () => import('../components/vod/citation/VodCitationList.vue'),
            },
            {
                path: 'analysis',
                name: 'VodAnalysis',
                component: () => import('../components/vod/analysis/VideoAnalysis.vue'),
            },
            {
                path: 'sales',
                name: 'VodSales',
                component: () => import('../components/vod/sales/VideoSales.vue'),
            },
        ]
    },
    {
        path: '/short/:short_id',
        component: () => import('../views/Short'),
        children: [
            {
                path: '',
                name: 'Short',
                component: () => import('../components/short/EditShort'),
            },
            {
                path: 'supporter',
                name: 'ShortSupporter',
                component: () => import('../components/short/supporter/ShortSupporterList'),
            },
            {
                path: 'citations',
                name: 'ShortCitations',
                component: () => import('../components/short/citation/ShortCitationList.vue'),
            },
            {
                path: 'analysis',
                name: 'ShortAnalysis',
                component: () => import('../components/short/analysis/VideoAnalysis.vue'),
            },
        ]
    },
    {
        path: '/livestream/:livestream_id',
        component: () => import('../views/Livestream'),
        children: [
            {
                path: '',
                name: 'Livestream',
                component: () => import('../components/livestream/EditLivestream'),
            },
            {
                path: 'transmission',
                name: 'LivestreamTransmission',
                component: () => import('../components/livestream/transmission/Transmission'),
            },
            {
                path: 'chat',
                name: 'LivestreamChat',
                component: () => import('../components/livestream/chat/Chat'),
            },
            {
                path: 'polls',
                name: 'LivestreamPolls',
                component: () => import('../components/livestream/poll/Polls'),
            },
            {
                path: 'topics',
                name: 'LivestreamTopicsAndExperts',
                component: () => import('../components/livestream/topic/LivestreamTopicAndExpertList'),
            },
            {
                path: 'supporter',
                name: 'LivestreamSupporter',
                component: () => import('../components/livestream/supporter/LivestreamSupporterList'),
            },
            {
                path: 'certificates',
                name: 'LivestreamCertificates',
                component: () => import('../components/livestream/certificate/LivestreamCertificateList'),
            },
            {
                path: 'downloads',
                name: 'LivestreamDownloads',
                component: () => import('../components/livestream/download/LivestreamDownloadList'),
            },
            {
                path: 'access',
                name: 'LivestreamAccess',
                component: () => import('../components/livestream/LivestreamAccess'),
            },
            {
                path: 'citations',
                name: 'LivestreamCitations',
                component: () => import('../components/livestream/citation/LivestreamCitationList'),
            },
            {
                path: 'analysis',
                name: 'LivestreamAnalysis',
                component: () => import('../components/livestream/analysis/LivestreamAnalysis'),
            },
            {
                path: 'sales',
                name: 'LivestreamSales',
                component: () => import('../components/livestream/sales/LivestreamSales.vue'),
            },
        ]
    },
    {
        path: '/event/:event_id',
        component: () => import('../views/Event'),
        children: [
            {
                path: '',
                name: 'Event',
                component: () => import('../components/event/EditEvent'),
            },
            {
                path: 'transmission',
                component: () => import('../components/event/transmission/TransmissionWrapper.vue'),
                children: [
                    {
                        path: '',
                        name: 'EventTransmission',
                        component: () => import('../components/event/transmission/Transmission'),
                    },
                    {
                        path: 'breakout/:event_breakout_id',
                        name: 'EventTransmissionBreakout',
                        component: () => import('../components/event/transmission/BreakoutTransmission'),
                    },
                ]
            },
            {
                path: 'chat',
                name: 'EventChat',
                component: () => import('../components/event/chat/Chat.vue'),
            },
            {
                path: 'polls',
                name: 'EventPolls',
                component: () => import('../components/event/poll/Polls.vue'),
            },
            {
                path: 'info',
                name: 'EventInfoTab',
                component: () => import('../components/event/info_tab/EventInfoTab'),
            },
            {
                path: 'agenda',
                name: 'EventAgenda',
                component: () => import('../components/event/agenda/EventAgenda'),
            },
            {
                path: 'zoom-sessions',
                name: 'EventZoomSessions',
                component: () => import('../components/event/zoom_sessions/EventZoomSessions'),
            },
            {
                path: 'experts',
                name: 'EventExperts',
                component: () => import('../components/event/expert/EventExpertList'),
            },
            {
                path: 'bundle',
                name: 'EventBundle',
                component: () => import('../components/event/bundle/EventBundleList'),
            },
            {
                path: 'supporter',
                name: 'EventSupporter',
                component: () => import('../components/event/supporter/EventSupporterList'),
            },
            {
                path: 'downloads',
                name: 'EventDownloads',
                component: () => import('../components/event/download/EventDownloadList'),
            },
            {
                path: 'analysis',
                name: 'EventAnalysis',
                component: () => import('../components/event/analysis/EventAnalysis'),
            },
            {
                path: 'sales',
                name: 'EventSales',
                component: () => import('../components/event/sales/EventSales.vue'),
            },
        ]
    },
    {
        path: '/poll_result/livestream/:livestream_id',
        name: 'LivestreamPollResult',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {hideMenu: true},
        component: () => import('../components/livestream/poll/LivestreamPollResult'),
    },
    {
        path: '/poll_result/event/:event_id',
        name: 'EventPollResult',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {hideMenu: true},
        component: () => import('../components/event/poll/EventPollResult'),
    },
    {
        path: '/main_menu',
        component: () => import('../views/MainMenu'),
        children: [
            {
                path: '/',
                name: 'MenuPartnersPlace',
                component: () => import('../components/main_menu/partners_place/PartnersPlace'),
            },
            {
                path: 'societies',
                name: 'MenuSocieties',
                component: () => import('../components/main_menu/societies/Societies'),
            },
            {
                path: 'home_featured_content',
                name: 'HomeFeaturedContent',
                component: () => import('../components/main_menu/featuredcontent/HomeFeaturedContentCalendar'),
            },
            {
                path: 'faq',
                name: 'Faq',
                component: () => import('../components/main_menu/faq/FaqList'),
            },
        ]
    },
    {
        path: '/index_page',
        component: () => import('../views/IndexPage'),
        children: [
            {
                path: '/',
                name: 'IndexPageTestimonials',
                component: () => import('../components/index_page/testimonials/Testimonials'),
            },
            {
                path: 'faqs',
                name: 'IndexPageFaqs',
                component: () => import('../components/index_page/faqs/Faqs'),
            },
        ]
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('../views/Maintenance'),
        meta: {hideMenu: true},
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error'),
        meta: {hideMenu: true},
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.path.includes('user') && from.path.includes('user')) {
        router.app.$store.dispatch('userManagement/resetFilter')
    }
    next()
});

export default router
